import * as i0 from '@angular/core';
import { inject, ViewContainerRef, Directive, Component, ChangeDetectionStrategy, InjectionToken, Inject, Optional, Self, Input, NgModule } from '@angular/core';
import * as i1 from '@angular/common';
import { CommonModule } from '@angular/common';
import { fromEvent, Subject, EMPTY, merge, NEVER } from 'rxjs';
import { tap, shareReplay, distinctUntilChanged, switchMap, startWith, mapTo, takeUntil } from 'rxjs/operators';
import * as i3 from '@angular/forms';
function DefaultControlErrorComponent_label_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "label", 2);
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵclassProp("hide-control", ctx_r0.hideError);
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate(ctx_r0.errorText);
  }
}
function DefaultControlErrorComponent_1_ng_template_0_Template(rf, ctx) {}
function DefaultControlErrorComponent_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtemplate(0, DefaultControlErrorComponent_1_ng_template_0_Template, 0, 0, "ng-template");
  }
}
class ControlErrorAnchorDirective {
  constructor() {
    this.vcr = inject(ViewContainerRef);
  }
}
ControlErrorAnchorDirective.ɵfac = function ControlErrorAnchorDirective_Factory(t) {
  return new (t || ControlErrorAnchorDirective)();
};
ControlErrorAnchorDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: ControlErrorAnchorDirective,
  selectors: [["", "controlErrorAnchor", ""]],
  exportAs: ["controlErrorAnchor"],
  standalone: true
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ControlErrorAnchorDirective, [{
    type: Directive,
    args: [{
      selector: '[controlErrorAnchor]',
      standalone: true,
      exportAs: 'controlErrorAnchor'
    }]
  }], null, null);
})();
class DefaultControlErrorComponent {
  constructor(cdr, host) {
    this.cdr = cdr;
    this.host = host;
    this.errorText = null;
    this.hideError = true;
    this._addClasses = [];
  }
  createTemplate(tpl, error, text) {
    this.errorTemplate = tpl;
    this.errorContext = {
      $implicit: error,
      text
    };
    this.cdr.markForCheck();
  }
  set customClass(classes) {
    if (!this.hideError) {
      this._addClasses = Array.isArray(classes) ? classes : classes.split(/\s/);
      this.host.nativeElement.classList.add(...this._addClasses);
    }
  }
  set text(value) {
    if (value !== this.errorText) {
      this.errorText = value;
      this.hideError = !value;
      if (this.hideError) {
        this.host.nativeElement.classList.remove(...this._addClasses);
      }
      this.cdr.markForCheck();
    }
  }
}
DefaultControlErrorComponent.ɵfac = function DefaultControlErrorComponent_Factory(t) {
  return new (t || DefaultControlErrorComponent)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef));
};
DefaultControlErrorComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: DefaultControlErrorComponent,
  selectors: [["control-error"]],
  standalone: true,
  features: [i0.ɵɵStandaloneFeature],
  decls: 2,
  vars: 3,
  consts: [["class", "control-error", 3, "hide-control", 4, "ngIf"], [4, "ngTemplateOutlet", "ngTemplateOutletContext"], [1, "control-error"]],
  template: function DefaultControlErrorComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵtemplate(0, DefaultControlErrorComponent_label_0_Template, 2, 3, "label", 0)(1, DefaultControlErrorComponent_1_Template, 1, 0, null, 1);
    }
    if (rf & 2) {
      i0.ɵɵproperty("ngIf", !ctx.errorTemplate);
      i0.ɵɵadvance();
      i0.ɵɵproperty("ngTemplateOutlet", ctx.errorTemplate)("ngTemplateOutletContext", ctx.errorContext);
    }
  },
  dependencies: [CommonModule, i1.NgIf, i1.NgTemplateOutlet],
  styles: [".hide-control[_ngcontent-%COMP%]{display:none!important}[_nghost-%COMP%]{display:block}"],
  changeDetection: 0
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DefaultControlErrorComponent, [{
    type: Component,
    args: [{
      selector: 'control-error',
      standalone: true,
      imports: [CommonModule],
      template: `
    <label class="control-error" [class.hide-control]="hideError" *ngIf="!errorTemplate">{{ errorText }}</label>
    <ng-template *ngTemplateOutlet="errorTemplate; context: errorContext"></ng-template>
  `,
      changeDetection: ChangeDetectionStrategy.OnPush,
      styles: [".hide-control{display:none!important}:host{display:block}\n"]
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }];
  }, null);
})();
const FORM_ERRORS = new InjectionToken('FORM_ERRORS', {
  providedIn: 'root',
  factory: () => {
    return {};
  }
});
const ErrorTailorConfigProvider = new InjectionToken('ErrorTailorConfigProvider');
class FormActionDirective {
  constructor(host) {
    this.host = host;
    this.submit$ = fromEvent(this.element, 'submit').pipe(tap(() => {
      if (this.element.classList.contains('form-submitted') === false) {
        this.element.classList.add('form-submitted');
      }
    }), shareReplay({
      refCount: true,
      bufferSize: 1
    }));
    this.reset$ = fromEvent(this.element, 'reset').pipe(tap(() => {
      this.element.classList.remove('form-submitted');
    }), shareReplay({
      refCount: true,
      bufferSize: 1
    }));
  }
  get element() {
    return this.host.nativeElement;
  }
}
FormActionDirective.ɵfac = function FormActionDirective_Factory(t) {
  return new (t || FormActionDirective)(i0.ɵɵdirectiveInject(i0.ElementRef));
};
FormActionDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: FormActionDirective,
  selectors: [["form", "errorTailor", ""]],
  standalone: true
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(FormActionDirective, [{
    type: Directive,
    args: [{
      standalone: true,
      selector: 'form[errorTailor]'
    }]
  }], function () {
    return [{
      type: i0.ElementRef
    }];
  }, null);
})();
class ControlErrorsDirective {
  constructor(vcr, host, config, globalErrors, controlErrorAnchorParent, form, ngControl, controlContainer) {
    this.vcr = vcr;
    this.host = host;
    this.config = config;
    this.globalErrors = globalErrors;
    this.controlErrorAnchorParent = controlErrorAnchorParent;
    this.form = form;
    this.ngControl = ngControl;
    this.controlContainer = controlContainer;
    this.customErrors = {};
    this.destroy = new Subject();
    this.showError$ = new Subject();
    this.mergedConfig = {};
    this.submit$ = this.form ? this.form.submit$ : EMPTY;
    this.reset$ = this.form ? this.form.reset$ : EMPTY;
  }
  ngOnInit() {
    this.mergedConfig = this.buildConfig();
    this.anchor = this.resolveAnchor();
    this.control = (this.controlContainer || this.ngControl).control;
    const hasAsyncValidator = !!this.control.asyncValidator;
    const statusChanges$ = this.control.statusChanges.pipe(distinctUntilChanged());
    const valueChanges$ = this.control.valueChanges;
    const controlChanges$ = merge(statusChanges$, valueChanges$);
    let changesOnAsync$ = EMPTY;
    let changesOnBlur$ = EMPTY;
    let changesOnChange$ = EMPTY;
    if (!this.controlErrorsClass || this.controlErrorsClass?.length === 0) {
      if (this.mergedConfig.controlErrorsClass && this.mergedConfig.controlErrorsClass) {
        this.controlErrorsClass = this.mergedConfig.controlErrorsClass;
      }
    }
    if (!this.controlCustomClass || this.controlCustomClass?.length === 0) {
      if (this.mergedConfig.controlCustomClass && this.mergedConfig.controlCustomClass) {
        this.controlCustomClass = this.mergedConfig.controlCustomClass;
      }
    }
    if (this.mergedConfig.controlErrorsOn.async && hasAsyncValidator) {
      // hasAsyncThenUponStatusChange
      changesOnAsync$ = statusChanges$;
    }
    if (this.isInput && this.mergedConfig.controlErrorsOn.change) {
      // on each change
      changesOnChange$ = valueChanges$;
    }
    if (this.isInput && this.mergedConfig.controlErrorsOn.blur) {
      const blur$ = fromEvent(this.host.nativeElement, 'focusout');
      // blurFirstThenUponChange
      changesOnBlur$ = blur$.pipe(switchMap(() => valueChanges$.pipe(startWith(true))));
    }
    const submit$ = merge(this.submit$.pipe(mapTo(true)), this.reset$.pipe(mapTo(false)));
    // when submitted, submitFirstThenUponChanges
    const changesOnSubmit$ = submit$.pipe(switchMap(submit => submit ? controlChanges$.pipe(startWith(true)) : NEVER));
    // on reset, clear ComponentRef and customAnchorDestroyFn
    this.reset$.pipe(takeUntil(this.destroy)).subscribe(() => this.clearRefs());
    merge(changesOnAsync$, changesOnBlur$, changesOnChange$, changesOnSubmit$, this.showError$).pipe(takeUntil(this.destroy)).subscribe(() => this.valueChanges());
  }
  setError(text, error) {
    if (!this.ref) {
      this.ref = this.anchor.createComponent(this.mergedConfig.controlErrorComponent);
    }
    const instance = this.ref.instance;
    if (this.controlErrorsTpl) {
      instance.createTemplate(this.controlErrorsTpl, error, text);
    } else {
      instance.text = text;
    }
    if (this.controlErrorsClass) {
      instance.customClass = this.controlErrorsClass;
    }
    if (!this.controlErrorAnchor && this.mergedConfig.controlErrorComponentAnchorFn) {
      this.customAnchorDestroyFn = this.mergedConfig.controlErrorComponentAnchorFn(this.host.nativeElement, this.ref.hostView.rootNodes[0]);
    }
  }
  /**
   * Explicit showing of a control error via some custom application code.
   */
  showError() {
    this.showError$.next();
  }
  /**
   * Explicit hiding of a control error via some custom application code.
   */
  hideError() {
    this.setError(null);
  }
  ngOnDestroy() {
    this.destroy.next();
    this.clearRefs();
  }
  get isInput() {
    return this.mergedConfig.blurPredicate(this.host.nativeElement);
  }
  clearRefs() {
    if (this.customAnchorDestroyFn) {
      this.customAnchorDestroyFn();
      this.customAnchorDestroyFn = null;
    }
    if (this.ref) {
      this.ref.destroy();
    }
    this.ref = null;
  }
  valueChanges() {
    const controlErrors = this.control.errors;
    const classesAdd = Array.isArray(this.controlCustomClass) ? this.controlCustomClass : this.controlCustomClass?.split(/\s/) ?? [];
    if (controlErrors) {
      const [firstKey] = Object.keys(controlErrors);
      const getError = this.customErrors[firstKey] || this.globalErrors[firstKey];
      if (!getError) {
        return;
      }
      const text = typeof getError === 'function' ? getError(controlErrors[firstKey]) : getError;
      if (this.isInput) {
        this.host.nativeElement.parentElement.classList.add('error-tailor-has-error');
        if (this.controlCustomClass) {
          this.host.nativeElement.classList.add(...classesAdd);
        }
      }
      this.setError(text, controlErrors);
    } else if (this.ref) {
      if (this.isInput) {
        this.host.nativeElement.parentElement.classList.remove('error-tailor-has-error');
        if (this.controlCustomClass) {
          this.host.nativeElement.classList.remove(...classesAdd);
        }
      }
      this.setError(null);
    }
  }
  resolveAnchor() {
    if (this.controlErrorAnchor) {
      return this.controlErrorAnchor.vcr;
    }
    if (this.controlErrorAnchorParent) {
      return this.controlErrorAnchorParent.vcr;
    }
    return this.vcr;
  }
  buildConfig() {
    return {
      ...{
        blurPredicate(element) {
          return element.tagName === 'INPUT' || element.tagName === 'SELECT';
        },
        controlErrorComponent: DefaultControlErrorComponent
      },
      ...this.config,
      controlErrorsOn: {
        async: this.controlErrorsOnAsync ?? this.config.controlErrorsOn?.async ?? true,
        blur: this.controlErrorsOnBlur ?? this.config.controlErrorsOn?.blur ?? true,
        change: this.controlErrorsOnChange ?? this.config.controlErrorsOn?.change ?? false
      }
    };
  }
}
ControlErrorsDirective.ɵfac = function ControlErrorsDirective_Factory(t) {
  return new (t || ControlErrorsDirective)(i0.ɵɵdirectiveInject(i0.ViewContainerRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(ErrorTailorConfigProvider), i0.ɵɵdirectiveInject(FORM_ERRORS), i0.ɵɵdirectiveInject(ControlErrorAnchorDirective, 8), i0.ɵɵdirectiveInject(FormActionDirective, 8), i0.ɵɵdirectiveInject(i3.NgControl, 10), i0.ɵɵdirectiveInject(i3.ControlContainer, 10));
};
ControlErrorsDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: ControlErrorsDirective,
  selectors: [["", "formControlName", "", 3, "controlErrorsIgnore", ""], ["", "formControl", "", 3, "controlErrorsIgnore", ""], ["", "formGroup", "", 3, "controlErrorsIgnore", ""], ["", "formGroupName", "", 3, "controlErrorsIgnore", ""], ["", "formArrayName", "", 3, "controlErrorsIgnore", ""], ["", "ngModel", "", 3, "controlErrorsIgnore", ""]],
  inputs: {
    customErrors: [i0.ɵɵInputFlags.None, "controlErrors", "customErrors"],
    controlErrorsClass: "controlErrorsClass",
    controlCustomClass: "controlCustomClass",
    controlErrorsTpl: "controlErrorsTpl",
    controlErrorsOnAsync: "controlErrorsOnAsync",
    controlErrorsOnBlur: "controlErrorsOnBlur",
    controlErrorsOnChange: "controlErrorsOnChange",
    controlErrorAnchor: "controlErrorAnchor"
  },
  exportAs: ["errorTailor"],
  standalone: true
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ControlErrorsDirective, [{
    type: Directive,
    args: [{
      standalone: true,
      selector: '[formControlName]:not([controlErrorsIgnore]), [formControl]:not([controlErrorsIgnore]), [formGroup]:not([controlErrorsIgnore]), [formGroupName]:not([controlErrorsIgnore]), [formArrayName]:not([controlErrorsIgnore]), [ngModel]:not([controlErrorsIgnore])',
      exportAs: 'errorTailor'
    }]
  }], function () {
    return [{
      type: i0.ViewContainerRef
    }, {
      type: i0.ElementRef
    }, {
      type: undefined,
      decorators: [{
        type: Inject,
        args: [ErrorTailorConfigProvider]
      }]
    }, {
      type: undefined,
      decorators: [{
        type: Inject,
        args: [FORM_ERRORS]
      }]
    }, {
      type: ControlErrorAnchorDirective,
      decorators: [{
        type: Optional
      }]
    }, {
      type: FormActionDirective,
      decorators: [{
        type: Optional
      }]
    }, {
      type: i3.NgControl,
      decorators: [{
        type: Optional
      }, {
        type: Self
      }]
    }, {
      type: i3.ControlContainer,
      decorators: [{
        type: Optional
      }, {
        type: Self
      }]
    }];
  }, {
    customErrors: [{
      type: Input,
      args: ['controlErrors']
    }],
    controlErrorsClass: [{
      type: Input
    }],
    controlCustomClass: [{
      type: Input
    }],
    controlErrorsTpl: [{
      type: Input
    }],
    controlErrorsOnAsync: [{
      type: Input
    }],
    controlErrorsOnBlur: [{
      type: Input
    }],
    controlErrorsOnChange: [{
      type: Input
    }],
    controlErrorAnchor: [{
      type: Input
    }]
  });
})();
const _errorTailorImports = [ControlErrorsDirective, ControlErrorAnchorDirective, DefaultControlErrorComponent, FormActionDirective];
class errorTailorImports {}
errorTailorImports.ɵfac = function errorTailorImports_Factory(t) {
  return new (t || errorTailorImports)();
};
errorTailorImports.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: errorTailorImports
});
errorTailorImports.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  imports: [DefaultControlErrorComponent]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(errorTailorImports, [{
    type: NgModule,
    args: [{
      imports: [_errorTailorImports],
      exports: [_errorTailorImports]
    }]
  }], null, null);
})();
function provideErrorTailorConfig(config) {
  return [{
    provide: ErrorTailorConfigProvider,
    useValue: config
  }, {
    provide: FORM_ERRORS,
    ...config.errors
  }];
}

/*
 * Public API Surface of error-tailor
 */

/**
 * Generated bundle index. Do not edit.
 */

export { ControlErrorAnchorDirective, ControlErrorsDirective, DefaultControlErrorComponent, ErrorTailorConfigProvider, FORM_ERRORS, FormActionDirective, errorTailorImports, provideErrorTailorConfig };
